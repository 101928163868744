
import {defineComponent, ref, watch, onMounted} from 'vue';
import {ElConfigProvider} from 'element-plus';
import ptBr from 'element-plus/lib/locale/lang/pt-br';
import useEmitter from '@/composables/Emmiter'
import ApiService from '@/services/ApiService';
import {useStore} from 'vuex';
import { codgruposelect } from "@/services/Concessionaria";
import FilterBase from '@/layout/header/partials/filters/FilterBase.vue';
export default defineComponent({
  name: "filtro-concessionaria",
  components: {
    FilterBase,
    ElConfigProvider,
  },
  setup(props, {emit}) {
    const store = useStore();
    const disabledClick = ref(false);
    let valorInicialCodConcessionaria;
    if (window.localStorage.getItem("id_usuario")) {
      valorInicialCodConcessionaria = window.localStorage.getItem("filtroConcessionaria") ?? "";
    } else {
      valorInicialCodConcessionaria = window.sessionStorage.getItem("filtroConcessionaria") ?? "";
    }
    const codConcessionariaSelected: any = ref("");
    const emitter = useEmitter();
    const activeModal = ref(false);
    const closeModal = ref(false);
    const nomeServico: any = ref("");
    const label = ref("Faça filtros por concessionária, nome do serviço e situação");
    const lastEmitIsNull = ref(false);
    const modalFiltro: any = ref();
    const showClose = ref(false)
    const motivoSelected: any = ref([]);
    const statusSelected: any = ref([]);
    const pecaSelected: any = ref([]);
    const optionsConcessionaria: any = ref([]);
    const optionsMotivosReprovacao: any = ref([]);
    const optionsPecas: any = ref([]);
    const codigoPecaServico = ref("");
    const codigoMOServico = ref("");
    const situacaoServico = ref(1);
    const codAtria = ref("");
    const codgruposOptions:any = ref([])
    const dadosGrupo :any = ref([])
    const filter = ref(['']);
    const loading: any = ref({
      concessionaria: false
    });
    const showGoToBack = ref(false);
    onMounted(() => {
      if (store.getters.concessionariasList.length > 0) {
        trataConcessionarias();
      }
    });
    store.watch(() => store.getters.concessionariasList, () => {
      trataConcessionarias();
    }, {deep: true});
    
    async function trataConcessionarias() {
      const concessionarias = store.getters.concessionariasList;
      optionsConcessionaria.value = concessionarias.map(concessionaria => {
        return {
          text: concessionaria.nomeFantasia,
          value: concessionaria.codConcessionaria,
        }
      })
    }

    async function buscarCodgrupo() {
      let response = await codgruposelect();
       codgruposOptions.value = response.map(codgrupo => {
          const option =  {
            text: codgrupo.nome,
            value: codgrupo.codGrupo
          }
          return option;
        })
    }
	
    async function emiteMudanca() {
      label.value = "Faça filtros por concessionária, código do fornecedor, situação e grupo";
      showGoToBack.value = false;
      await ApiService.valideTokenTime();
      if (window.localStorage.getItem("id_usuario")) {
        window.localStorage.setItem("filtroConcessionaria", codConcessionariaSelected.value);
      } else {
        window.sessionStorage.setItem("filtroConcessionaria", codConcessionariaSelected.value);
      }
      lastEmitIsNull.value = true;

      emitter.emit("filtrar-crud-concessionaria", {
        filtrosServicos: {
          codConcessionariaSelected: filter.value.includes('codconcessionaria') ? codConcessionariaSelected.value : "",
          situacaoServico: filter.value.includes('situacao') ? situacaoServico : [],
          codAtria: filter.value.includes('codAtria') ? codAtria.value : "",
          dadosGrupo: filter.value.includes('grupo') ? dadosGrupo.value : "", 
        }
      });
      closeModal.value = !closeModal.value;
      return;
    }

    return {
      activeModal,
      closeModal,
      showClose,
      modalFiltro,
      loading,
      ptBr,
      emitter,
      emiteMudanca,
      lastEmitIsNull,
      optionsConcessionaria,
      optionsMotivosReprovacao,
      optionsPecas,
      codConcessionariaSelected,
      motivoSelected,
      pecaSelected,
      showGoToBack,
      label,
      statusSelected,
      disabledClick,
      nomeServico,
      codigoPecaServico,
      codigoMOServico,
      situacaoServico,
      codAtria,
      buscarCodgrupo,
      codgruposOptions,
      dadosGrupo,
      filter
    }
  }
});
